import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'Download',
    component: () => import('../views/Download.vue'),
    meta: {
      title: 'Download'
    }
  },
  {
    path: '/Guide',
    name: 'Guide',
    component: () => import('../views/Guide.vue'),
    meta: {
      title: 'Guide'
    }
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue'),
    meta: {
      title: 'FAQ'
    }
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title + ' - XIAMEN HANIN ELECTRONIC TECHNOLOGY CO.,LTD.'
    next()
})
export default router