<template>
	<ucHead></ucHead>
	<router-view />
	<ucFoot></ucFoot>
</template>
<script>
	import ucHead from './components/ucHead.vue'
	import ucFoot from './components/ucFoot.vue'
	export default {
		name: 'App',
		components: {
			ucHead,
			ucFoot
		}
	}
</script>