<template>
    <div class="uc-wrap">
        <header>
            <div class="bgs"><img src="@/assets/images/banner.jpg" alt=""></div>
            <div class="name">
                <div class="logo"><a href=""><img src="@/assets/images/logo.png" alt="" class="uc-icon50"></a></div>
                <h1>Customer Service Center</h1>
            </div>
            <div class="menu">
                <router-link class="item" to="/">Download</router-link>
                <!-- <router-link class="item" to="/Guide">Video Guides</router-link> -->
                <router-link class="item" to="/FAQ">FAQ</router-link>
            </div>
        </header>
    </div>
</template>
<script>
export default {
    name: 'ucHead',
    data() {
        return {

        }
    },
    methods: {

    },
}
</script>