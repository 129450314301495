<template>
    <div class="uc-wrap">
        <div class="fixed-operate">
            <a class="item" href="skype:live:.cid.a0d1b05a2c0e4915?chat"><img src="@/assets/images/skype.png" alt="" class="icon1"></a>
            <a class="item" href="mailto:sk@hprt.com"><img src="@/assets/images/mail.png" alt="" class="icon2"></a>
        </div>
        <div class="uc-content">
            <div class="contact-us">
                Talk to a real person:
                <a href="skype:live:.cid.a0d1b05a2c0e4915?chat"><img src="@/assets/images/skype.png" alt="" class="icon1"></a>
                <a href="mailto:sk@hprt.com"><img src="@/assets/images/mail.png" alt="" class="icon2"></a>
            </div>
        </div>
        <div class="footer-space"></div>
        <footer>
            <div class="uc-content">
                XIAMEN HANIN ELECTRONIC TECHNOLOGY CO.,LTD. ALL RIGHTS RESERVED. ©2010-2016 <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备13013356号</a>
            </div>
        </footer>
    </div>
</template>
<script>
    export default {
        name: 'ucFoot',
        data() {
            return {}
        },
        methods: {},
    }
</script>